import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogType } from '../../models/dialog-type';

@Component({
  selector: 'to-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() dialogType: DialogType = DialogType.NEUTRAL;
  @Input() titleLabelKey?: string;

  @Input() okLabelKey?: string;
  @Input() cancelLabelKey?: string;

  @Input() errorLabelKey?: string;

  @Output() okButtonClick = new EventEmitter();
  @Output() cancelButtonClick = new EventEmitter();
  @Output() closeButtonClick = new EventEmitter();

  DialogType = DialogType;
}
