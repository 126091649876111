import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'to-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrl: './time-picker.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
  ],
})
export class TimePickerComponent implements ControlValueAccessor, OnChanges, OnInit {
  private _value?: string;

  @ViewChild('columnHours')
  set scrollContainerRefHours(elementRef: ElementRef) {
    if (elementRef) {
      this.columnHours = elementRef;
      this.scrollTo(this.columnHours, 'hour-' + this.selectedHour);
    }
  }

  columnHours: ElementRef;

  @ViewChild('columnMinutes')
  set scrollContainerRefMinutes(elementRef: ElementRef) {
    if (elementRef) {
      this.columnMinutes = elementRef;
      this.scrollTo(this.columnMinutes, 'minute-' + this.selectedMinute);
    }
  }

  columnMinutes: ElementRef;

  @Input()
  label: string;

  @Input()
  format12Hours = false;

  @Input()
  alignTop = false;

  @Input()
  get value(): string | undefined {
    return this._value;
  }

  set value(value: string | undefined) {
    this.checkAndSetValue(value);
  }

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  id: string;
  hours: string[];
  minutes = Array.from({ length: 60 }, (_, i) => {
    return i < 10 ? `0${i}` : `${i}`;
  });
  meridiems = ['AM', 'PM'];

  selectedHour = '';
  selectedMinute = '';
  selectedMeridiem = '';
  showPicker = false;

  ngOnChanges(changes: SimpleChanges): void {
    // regenerate hours if format12Hours changes
    if (changes['format12Hours']) {
      this.generateHours();
    }
  }

  ngOnInit() {
    // random id to avoid conflicts if multiple time pickers exist on a page
    this.id = `time-picker-${Math.floor(Math.random() * 9000) + 1000}`;
    this.generateHours();
  }

  /*-----METHODS USED FOR REACTIVE FORMS-----*/
  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  onBlur(): void {
    this.onTouched();
  }

  private onChange: (value: string) => void;

  private onTouched: () => void;

  /*-----END METHODS USED FOR REACTIVE FORMS-----*/

  openPicker() {
    if (!this.value) {
      this.setDefaultValues();
    }
    this.showPicker = true;
  }

  closePicker() {
    this.showPicker = false;
  }

  togglePicker() {
    if (this.showPicker) {
      this.closePicker();
    } else {
      this.openPicker();
    }
  }

  setHour(hour: string): void {
    this.selectedHour = hour;
    this.updateValue();
  }

  setMinute(minute: string): void {
    this.selectedMinute = minute;
    this.updateValue();
  }

  setMeridiem(meridiem: string): void {
    this.selectedMeridiem = meridiem;
    this.updateValue();
  }

  private generateHours() {
    this.hours = Array.from({ length: this.format12Hours ? 12 : 24 }, (_, i) => {
      return this.format12Hours ? `${i + 1}` : i < 10 ? `0${i}` : `${i}`;
    });
  }

  private checkAndSetValue(value?: string) {
    if (value === this._value) {
      return;
    }
    if (!value && this._value) {
      // value is set by the parent, don't emit the value again to avoid errors
      this.reset(false);
      return;
    }
    if (value) {
      const [hourStr, minuteStr] = value
        .replace(/AM|PM|am|pm/g, '')
        .split(':')
        .map((str) => str.trim());
      // make sure the hour/minute values are valid
      if (Number.isNaN(Number(hourStr)) || Number.isNaN(Number(minuteStr))) {
        this.reset();
        return;
      }
      // determine ante or post meridiem
      if (value.toUpperCase().includes('AM') || value.toUpperCase().includes('PM')) {
        this.selectedMeridiem = value.toUpperCase().includes('AM') ? 'AM' : 'PM';
        // if AM/PM string exists, set hour format to 12h
        this.format12Hours = true;
      }
      this.selectedHour = hourStr;
      this.selectedMinute = minuteStr;

      this.updateValue(false);
    }
  }

  private setDefaultValues() {
    const now = new Date();
    const hour = now.getHours();
    const minute = now.getMinutes();
    if (this.format12Hours) {
      this.selectedHour = `${hour > 12 ? hour - 12 : hour}`;
    } else {
      this.selectedHour = `${hour < 10 ? `0${hour}` : hour}`;
    }
    this.selectedMinute = `${minute < 10 ? `0${minute}` : minute}`;
    this.selectedMeridiem = now.getHours() < 12 ? 'AM' : 'PM';
  }

  private updateValue(emitValue = true) {
    const newValue = this.format12Hours
      ? `${this.selectedHour}:${this.selectedMinute} ${this.selectedMeridiem}`
      : `${this.selectedHour}:${this.selectedMinute}`;
    if (emitValue) {
      this.value = newValue;
      this.valueChange.emit(this.value);
      // only required for reactive forms
      if (typeof this.onChange === 'function') {
        this.onChange(this.value);
      }
    } else {
      this._value = newValue;
    }
  }

  private scrollTo(parent: ElementRef, childId: string): void {
    const elementToScrollTo = document.getElementById(childId);
    parent.nativeElement.scrollTop = elementToScrollTo?.offsetTop || 0;
  }

  private reset(emitValue = true) {
    this.selectedHour = '';
    this.selectedMinute = '';
    this.selectedMeridiem = '';
    this._value = '';
    if (emitValue) {
      this.valueChange.emit(this.value);
    }
  }
}
