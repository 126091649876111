export enum LocalStorage {
  DARKMODE_ENABLED = 'darkmodeEnabled',
  REDIRECT_URL = 'redirectUrl',
  SELECTED_TENANT = 'selectedTenant',
  SELECTED_LANGUAGE = 'selectedLanguage',
  OVERVIEW_PAGE = 'overviewPage',
}

export interface LocalStorageTypes {
  [LocalStorage.DARKMODE_ENABLED]: string;
  [LocalStorage.REDIRECT_URL]: string;
  [LocalStorage.SELECTED_LANGUAGE]: string;
  [LocalStorage.SELECTED_TENANT]: string;
  [LocalStorage.OVERVIEW_PAGE]: string;
}
