import { Component, Input } from '@angular/core';

@Component({
  selector: 'to-chip',
  templateUrl: './chip.component.html',
})
export class ChipComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() selected: boolean;
  @Input() badge?: String;
}
