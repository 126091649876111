import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'to-video-player-popup',
  templateUrl: './video-player-popup.component.html',
  styleUrl: './video-player-popup.component.scss',
})
export class VideoPlayerPopupComponent {
  @ViewChild('video') videoElement: ElementRef;

  @Input() url?: string;

  @Output() closeButtonClick = new EventEmitter();

  onVideoLoaded() {
    const video = this.videoElement.nativeElement;
    video.pause();
  }
}
