<div class="-size-m mt-5 highlight">
  {{ questionTextKey | translate }}
</div>
<ul class="a-option-bar option-bar mt-2">
  <li class="a-option-bar__item option-bar__yes">
    <input type="radio" id="yes" name="yesno" data-test="yes-input" (change)="toggleYesNoRadio.emit($event)" />
    <label class="a-option-bar__option h-12 flex items-center" for="yes">
      <frok-icon *ngIf="confirmationOption === true" [button]="true" bosch="checkmark"></frok-icon>
      <span class="a-option-bar__label">
        {{ 'GENERAL.yes' | translate }}
      </span>
    </label>
  </li>
  <li class="a-option-bar__item">
    <input type="radio" id="no" name="yesno" data-test="no-input" (change)="toggleYesNoRadio.emit($event)" />
    <label class="a-option-bar__option h-12 items-center" for="no">
      <frok-icon *ngIf="confirmationOption === false" [button]="true" bosch="close-small"></frok-icon>
      <span class="a-option-bar__label flex justify-items-center">
        {{ 'GENERAL.no' | translate }}
      </span>
    </label>
  </li>
</ul>
