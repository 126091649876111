import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Auth } from 'aws-amplify';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '../localstorage/localstorage.service';
import { LocalStorage } from '../../modules/shared/models/localstorage';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private localStorageService: LocalStorageService = inject(LocalStorageService);

  private _tenants: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  tenants$: Observable<string[]> = this._tenants.asObservable();

  selectedTenantSig: WritableSignal<string | undefined> = signal(undefined);

  async init(): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups = user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
      const allTenants = this.getAvailableTenants(groups);
      const allRoles = this.getAvailableRoles(groups);

      const tenants = allTenants.filter((tenant) => allRoles.includes(`${tenant}_ROLE_OPERATOR`));
      this._tenants.next(tenants);

      this.determineSelectedTenant(tenants);

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  selectTenant(tenant: string): boolean {
    if (this._tenants.value.includes(tenant)) {
      this.selectedTenantSig.set(tenant);
      this.localStorageService.set(LocalStorage.SELECTED_TENANT, tenant);
      return true;
    }

    return false;
  }

  private getAvailableTenants(groups: string[]): string[] {
    return groups.filter((group) => !group.includes('ROLE_') && !group.includes('eu-central'));
  }

  private getAvailableRoles(groups: string[]): string[] {
    return groups.filter((group) => group.includes('ROLE_'));
  }

  /**
   * Determines the selected tenant based on the previous selected tenant
   * Default to the first available tenant
   *
   * @param tenants
   * @private
   */
  private determineSelectedTenant(tenants: string[]): void {
    if (tenants && tenants.length > 0) {
      const lastTenant: string | null = this.localStorageService.get(LocalStorage.SELECTED_TENANT);
      if (lastTenant && tenants.includes(lastTenant)) {
        this.selectTenant(lastTenant);
      } else {
        this.selectTenant(tenants[0]);
      }
    }
  }
}
