<div class="backdrop"></div>
<dialog class="m-dialog -floating-shadow-s -floating fixed" open>
  @if (dialogType !== DialogType.NEUTRAL) {
  <div class="m-dialog__remark {{ dialogType }}"></div>
  }
  <div class="relative">
    <button
      type="button"
      class="a-button a-button--tertiary -without-label close-button"
      aria-label="accessibility label"
      data-test="close-button"
      (click)="closeButtonClick.emit()"
    >
      <frok-icon [button]="false" bosch="close" class="a-icon a-button__icon"></frok-icon>
    </button>
    <div class="m-dialog__content">
      @if (titleLabelKey !== undefined){
      <div class="m-dialog__headline">
        <span [innerHTML]="titleLabelKey | safeHtml"></span>
      </div>
      }
      <div class="m-dialog__body">
        <ng-content></ng-content>
      </div>
      <div class="m-dialog__actions">
        @if(cancelLabelKey !== undefined) {
        <button
          type="button"
          class="a-button a-button--secondary -without-icon flex-1"
          data-test="cancel-button"
          (click)="cancelButtonClick.emit()"
        >
          <span class="a-button__label">
            {{ cancelLabelKey | translate }}
          </span>
        </button>
        } @if(okLabelKey !== undefined) {
        <button
          type="button"
          class="a-button a-button--primary -without-icon flex-1"
          data-test="ok-button"
          (click)="okButtonClick.emit()"
        >
          <span class="a-button__label">
            {{ okLabelKey | translate }}
          </span>
        </button>
        }
      </div>
    </div>
    @if(errorLabelKey !== undefined) {
    <div class="a-notification -error" role="alert">
      <i class="a-icon ui-ic-alert-error"></i>
      <div
        id="notification-label-id-bar-error-error"
        class="a-notification__content"
        [innerHTML]="errorLabelKey | translate"
      ></div>
    </div>
    }
  </div>
</dialog>
