import { Component, Input } from '@angular/core';

@Component({
  selector: 'to-activity-indicator',
  templateUrl: './activity-indicator.component.html',
  styleUrls: ['./activity-indicator.component.scss'],
})
export class ActivityIndicatorComponent {
  // Set default to true in order to not break existing components
  @Input() hasTopMargin = true;
}
