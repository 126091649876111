<div class="background fixed inset-0 opacity-95 z-20" (click)="closeButtonClick.emit()"></div>
<div class="w-6/12 fixed top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 z-30">
  @if(url) {
    <video #video controls [src]="url" [autoplay]="true" (loadeddata)="onVideoLoaded()" class="w-full">
    </video>
    <button
      (click)="closeButtonClick.emit()"
      type="button"
      class="fixed top-0 right-0 a-button a-button--integrated -without-label"
      aria-label="close"
    >
      <frok-icon class="close" [button]="true" ui="close" />
    </button>
  } @else {
  <to-activity-indicator></to-activity-indicator>
  }
</div>
