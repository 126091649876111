import { Component, Input } from '@angular/core';
import { CreateIncidentReason } from '../../models/incident-reason';
import { Incident } from '../../models/incident';

@Component({
  selector: 'to-incident-type-text',
  templateUrl: './incident-type-text.component.html',
  styleUrl: './incident-type-text.component.scss',
})
export class IncidentTypeTextComponent {
  @Input({ required: true })
  incident: Incident;

  protected readonly createIncidentReasons: string[] = Object.values(CreateIncidentReason);
}
