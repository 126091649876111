import { DOCUMENT } from '@angular/common';
import { Directive, EventEmitter, Inject, Input, OnChanges, Output } from '@angular/core';

/**
 * This directive can be used together with `infiniteScroll`.
 * `infiniteScroll` only emits an event when the user scrolls the page.
 * If initially there is not enough data available that a scrollbar is shown, the `infiniteScroll` event will not be emitted.
 * This directive listens to changes of the `existingItems` input and emits the `loadMoreItems` event if the scrollbar is not shown.
 */
@Directive({
  selector: '[toInsufficientItems]',
})
export class InsufficientItemsDirective implements OnChanges {
  @Input() existingItems: number;
  @Output() loadMoreItems: EventEmitter<void> = new EventEmitter();

  private previousExistingItems: number;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnChanges(): void {
    const rootElement = this.document.documentElement;
    if (
      this.existingItems &&
      this.existingItems !== this.previousExistingItems &&
      rootElement.clientHeight === rootElement.scrollHeight
    ) {
      this.previousExistingItems = this.existingItems;
      this.loadMoreItems.emit();
    }
  }
}
