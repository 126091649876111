export enum CreateIncidentReason {
  ACCIDENT_BUTTON_PRESS = 'ACCIDENTAL',
  TECHNICAL_PROBLEMS = 'TECHNICAL_ISSUE',
  RIDE_INTERRUPTION = 'RIDE_INTERRUPTION',
  HEALTH_ISSUE = 'HEALTH_ISSUE',
  OTHER = 'OTHER',
}

export const createIncidentReasons: CreateIncidentReason[] = [
  CreateIncidentReason.ACCIDENT_BUTTON_PRESS,
  CreateIncidentReason.TECHNICAL_PROBLEMS,
  CreateIncidentReason.RIDE_INTERRUPTION,
  CreateIncidentReason.HEALTH_ISSUE,
  CreateIncidentReason.OTHER,
];
