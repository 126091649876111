<div
  class="a-notification a-notification--banner"
  [class.cursor-pointer]="linkSig()"
  [attr.role]="settingsSig().role"
  [ngClass]="[settingsSig().class, bannerService.isVisibleSig() ? '-show' : '']"
  (click)="bannerClick()"
>
  <frok-icon [bosch]="settingsSig().icon"></frok-icon>
  <div class="a-notification__content" *ngIf="textKeySig()" [innerHTML]="textKeySig() | translate : paramsSig()"></div>
  <button
    *ngIf="showCloseSig()"
    type="button"
    data-test="close-banner"
    class="a-button a-button--integrated -without-label"
    data-frok-action="close"
    [attr.aria-label]="'GENERAL.close' | translate"
    (click)="closeButtonClick()"
  >
    <frok-icon [button]="true" bosch="close"></frok-icon>
  </button>
</div>
