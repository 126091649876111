import { Injectable, signal, WritableSignal } from '@angular/core';
import { HeaderOptions } from '../../modules/shared/models/header-options';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  readonly showProfileSig: WritableSignal<boolean> = signal<boolean>(false);
  readonly showTenantSelectionSig: WritableSignal<boolean> = signal(false);

  setOptions(options: HeaderOptions): void {
    this.showProfileSig.set(!!options?.showProfile);
    this.showTenantSelectionSig.set(!!options?.showTenantSelection);
  }
}
