import { inject, Injectable } from '@angular/core';
import { CookieService } from '../cookie/cookie.service';
import { LocalStorage, LocalStorageTypes } from '../../modules/shared/models/localstorage';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private cookieService: CookieService = inject(CookieService);

  /**
   * Checks the privacy settings and returns the local storage item if allowed
   *
   * @param key
   * @param isEssential
   */
  get(key: LocalStorage, isEssential: boolean = false): Nullable<LocalStorageTypes[typeof key]> {
    if (this.cookieService.hasConvenienceConsent() || isEssential) {
      const value: string | null = localStorage.getItem(key);
      if (value) {
        return value as LocalStorageTypes[typeof key];
      }
    }
    return null;
  }

  /**
   * Checks the privacy settings and sets the local storage item if allowed
   *
   * @param key
   * @param value
   * @param isEssential
   */
  set(key: LocalStorage, value: LocalStorageTypes[typeof key], isEssential: boolean = false): boolean {
    if (this.cookieService.hasConvenienceConsent() || isEssential) {
      localStorage.setItem(key, value);
      return true;
    }
    return false;
  }

  /**
   * Removes an item from the local storage
   * @param key
   */
  delete(key: LocalStorage): void {
    localStorage.removeItem(key);
  }

  /**
   * Clears the entire local storage
   */
  clear(): void {
    localStorage.clear();
  }
}
