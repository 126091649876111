<div class="datetimepicker-container">
  <div class="mx-4 -size-s highlight">{{ 'DATE_TIME_PICKER.date' | translate }}</div>
  <div class="flex gap-2 mt-2 mx-4">
    <div class="a-dropdown flex-auto">
      <label for="month">{{ 'DATE_TIME_PICKER.month' | translate }}</label>
      <select id="month" [(ngModel)]="selectedMonth" (change)="selectMonth()">
        @for (month of months; track month) {
        <option [value]="month">{{ month }}</option>
        }
      </select>
    </div>
    <div class="a-dropdown flex-auto">
      <label for="year">{{ 'DATE_TIME_PICKER.year' | translate }}</label>
      <select id="year" [(ngModel)]="selectedYear">
        @for (year of years; track year) {
        <option [value]="year">{{ year }}</option>
        }
      </select>
    </div>
  </div>
  <div class="mx-2.5 my-7 grid grid-cols-7 gap-y-1">
    @for(day of weekdays; track day) {
    <div class="-size-s text-center highlight day">{{ day }}</div>
    } @for(day of daysInMonth; track day) {
    <div
      (click)="!day.disabled && selectDay(day)"
      class="-size-m cursor-pointer flex-1 day flex justify-center"
      [class.selectable]="!day.disabled"
      [class.disabled]="!day.isCurrentMonth || day.disabled"
      [class.selected]="day.selected && selectionEnd !== undefined"
      [class.rounded-l-2xl]="isSelectionStart(day)"
      [class.rounded-r-2xl]="isSelectionEnd(day)"
    >
      <div
        class="inner flex items-center justify-center"
        [class.start-selected]="day.selected && selectionEnd === undefined"
      >
        <span>{{ day.day }}</span>
      </div>
    </div>
    }
  </div>
  <div class="mx-4 -size-s highlight">{{ 'DATE_TIME_PICKER.selectTimeFrame' | translate }}</div>
  <div class="flex gap-2 mt-2 mx-4">
    <to-time-picker [(value)]="timeStart"
                    [label]="'DATE_TIME_PICKER.start' | translate"
                    [alignTop]="true"
                    [format12Hours]="false"
                    (valueChange)="checkTimeValidity()">
    </to-time-picker>
    <to-time-picker [(value)]="timeEnd"
                    [label]="'DATE_TIME_PICKER.end' | translate"
                    [alignTop]="true"
                    [format12Hours]="false"
                    (valueChange)="checkTimeValidity()">
    </to-time-picker>
  </div>
  <div class="a-notification a-notification--text -error mx-4 -size-s" role="alert" *ngIf="hasErrorSig()">
    <frok-icon bosch="alert-error" class="-size-s"></frok-icon>
    <div class="a-notification__content self-center">
      {{ 'DATE_TIME_PICKER.errorMessage.startAfterEnd' | translate }}
    </div>
  </div>
  <div class="flex gap-4 mt-8 mx-4">
    <button type="button" class="a-button a-button--secondary -without-icon" (click)="reset()">
      <span class="a-button__label">{{ 'DATE_TIME_PICKER.reset' | translate }}</span>
    </button>
    <button
      type="button"
      class="a-button a-button--primary -without-icon flex-1"
      [disabled]="selectionStart === undefined || selectionEnd === undefined || hasErrorSig()"
      (click)="onSubmit()"
    >
      <span class="a-button__label">{{ 'DATE_TIME_PICKER.ok' | translate }}</span>
    </button>
  </div>
</div>
