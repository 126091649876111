import {
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'aws-amplify';
import { LOGGED_IN_DEFAULT_ROUTE } from 'src/app/utils/constants';
import { DropdownItem } from '../../modules/shared/models/dropdown-item';
import { LocalStorage } from '../../modules/shared/models/localstorage';
import { HeaderService } from '../../services/header-service/header.service';
import { LocalStorageService } from '../../services/localstorage/localstorage.service';
import { TenantService } from '../../services/tenant/tenant.service';

@Component({
  selector: 'to-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() isLoginPage?: boolean;

  @ViewChild('contextMenu') contextMenu: ElementRef;

  readonly headerService = inject(HeaderService);
  readonly tenantService = inject(TenantService);

  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly translateService = inject(TranslateService);

  tenantsSig: WritableSignal<DropdownItem[]> = signal([]);
  languagesSig: WritableSignal<DropdownItem[]> = signal([]);
  selectedLanguageSig: WritableSignal<string> = signal('');

  profileMenuVisible: WritableSignal<boolean> = signal(false);
  isDarkmodeEnabled: boolean = false;

  loggedInRoute = LOGGED_IN_DEFAULT_ROUTE;

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.contextMenu.nativeElement.contains(event.target)) {
      this.profileMenuVisible.set(false);
    }
  }

  ngOnInit() {
    this.tenantService.tenants$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((tenants: string[]) => {
      const items: DropdownItem[] = [];
      tenants.forEach((tenant: string) => {
        items.push(this.buildTenantDropdownItem(tenant));
      });
      this.tenantsSig.set(items);
    });

    this.isDarkmodeEnabled = JSON.parse(this.localStorageService.get(LocalStorage.DARKMODE_ENABLED) || 'false');
    this.determineLanguageSelection();
  }

  changeLanguage(value: string): void {
    this.translateService.use(value);
    this.selectedLanguageSig.set(value);
    this.localStorageService.set(LocalStorage.SELECTED_LANGUAGE, value);
  }

  changeTenant(value: string): void {
    this.tenantService.selectTenant(value);
  }

  toggleDarkMode(): void {
    if (this.isDarkmodeEnabled) {
      document.getElementById('root-container')?.classList.add('-dark-mode');
    } else {
      document.getElementById('root-container')?.classList.remove('-dark-mode');
    }
    this.localStorageService.set(LocalStorage.DARKMODE_ENABLED, JSON.stringify(this.isDarkmodeEnabled));
  }

  logout(): void {
    Auth.signOut().then(() => {
      this.profileMenuVisible.set(false);
      this.router.navigate(['auth/login']);
    });
  }

  private buildTenantDropdownItem(tenant: string): DropdownItem {
    return {
      value: tenant,
      label: tenant,
    };
  }

  private determineLanguageSelection(): void {
    this.buildLanguagesDropdown();
    this.selectedLanguageSig.set(this.translateService.currentLang);

    // refresh the languages dropdown on language change
    this.translateService.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.buildLanguagesDropdown();
    });
  }

  private buildLanguagesDropdown(): void {
    const availableLanguages: string[] = this.translateService.getLangs();
    const languageDropdownItems: DropdownItem[] = this.buildLanguageDropdownItems(availableLanguages);
    this.languagesSig.set(languageDropdownItems);
  }

  private buildLanguageDropdownItems(langs: string[]): DropdownItem[] {
    const items: DropdownItem[] = [];
    langs.forEach((lang: string) => {
      items.push({
        value: lang,
        label: this.translateService.instant(`LANGUAGES.${lang}`),
      });
    });
    return items;
  }
}
