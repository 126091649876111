export interface PrivacyCookie {
  consent: {
    convenience: boolean;
    analysis: boolean;
    marketing: boolean;
  };
  timeToLive: number;
  timestamp: number;
  version: number;
}

export enum Cookie {
  BOSCH_PRIVACY = 'BoschPrivacy',
}

export interface CookieTypes {
  [Cookie.BOSCH_PRIVACY]: PrivacyCookie;
}
