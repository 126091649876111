import { Directive, ElementRef, EventEmitter, HostListener, inject, Output } from "@angular/core";

@Directive({
  selector: '[outsideClick]'
})
export class OutsideClickDirective {
  @Output()
  clickOutside = new EventEmitter<void>();

  private elementRef = inject(ElementRef);

  @HostListener('document:click', ['$event.target'])
  onClick(target: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}
