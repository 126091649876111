import { Component, HostBinding, Input } from '@angular/core';
import { BoschIcon, UIIcon } from './icons';

@Component({
  selector: 'frok-icon',
  template: '',
  styleUrls: [],
})
export class FrokIconComponent {
  @Input() bosch?: BoschIcon;
  @Input() ui?: UIIcon;

  @Input() button = false;

  @Input('class') classList?: string;

  @HostBinding('class') get classString(): string {
    return [
      'a-icon',
      this.whenTruthy(this.bosch, `boschicon-bosch-ic-${this.bosch}`),
      this.whenTruthy(this.ui, `ui-ic-${this.ui}`),
      this.whenTruthy(this.button, 'a-button__icon'),
    ]
      .concat(this.classList?.split(' ') ?? [])
      .filter(Boolean)
      .join(' ');
  }

  private whenTruthy<T, U>(value: T, result: U): U | undefined {
    return value ? result : undefined;
  }
}
