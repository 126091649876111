import { Injectable, signal, WritableSignal } from '@angular/core';
import { BannerConfig } from 'src/app/modules/shared/models/banner.config';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  readonly isVisibleSig: WritableSignal<boolean> = signal<boolean>(false);
  readonly bannerConfigSig: WritableSignal<Nullable<BannerConfig>> = signal(null);

  private deferred?: any;

  showBanner(options: BannerConfig): Promise<void> {
    this.isVisibleSig.set(true);
    this.bannerConfigSig.set(options);

    if (this.deferred) {
      this.deferred.reject();
      this.deferred = undefined;
    }

    this.deferred = {};
    this.deferred.promise = new Promise<void>((resolve, reject) => {
      this.deferred.resolve = resolve;
      this.deferred.reject = reject;
    });
    return this.deferred.promise;
  }

  closeBannerClick() {
    if (this.deferred) {
      this.deferred.resolve();
      this.deferred = undefined;
    }
    this.isVisibleSig.set(false);
    this.bannerConfigSig.set(null);
  }

  hideBanner() {
    if (this.deferred) {
      this.deferred.reject();
      this.deferred = undefined;
    }
    this.isVisibleSig.set(false);
    this.bannerConfigSig.set(null);
  }
}
