import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'to-yes-no-radio',
  templateUrl: './yes-no-radio.component.html',
  styleUrls: ['./yes-no-radio.component.scss'],
})
export class YesNoRadioComponent {
  @Input() confirmationOption?: boolean;
  @Input() questionTextKey: string = '';

  @Output() toggleYesNoRadio = new EventEmitter();
}
