import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderComponent } from 'src/app/components/header/header.component';
import { ActivityIndicatorComponent } from './components/activity-indicator/activity-indicator.component';
import { BannerComponent } from './components/banner/banner.component';
import { ChipComponent } from './components/chip/chip.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FrokIconComponent } from './components/frok-icon/frok-icon.component';
import { IncidentTypeTextComponent } from './components/incident-type-text/incident-type-text.component';
import { RcSpinnerComponent } from './components/rc-spinner/rc-spinner.component';
import { VideoPlayerPopupComponent } from './components/video-player-popup/video-player-popup.component';
import { YesNoRadioComponent } from './components/yes-no-radio/yes-no-radio.component';
import { InsufficientItemsDirective } from './directives/insufficient-items/insufficient-items.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { OutsideClickDirective } from './directives/outside-click/outside-click.directive';
import { TimePickerComponent } from './components/time-picker/time-picker.component';

const Components = [
  FrokIconComponent,
  HeaderComponent,
  DialogComponent,
  DropdownComponent,
  YesNoRadioComponent,
  RcSpinnerComponent,
  BannerComponent,
  ChipComponent,
  ActivityIndicatorComponent,
  VideoPlayerPopupComponent,
  IncidentTypeTextComponent,
  DateTimePickerComponent,
  TimePickerComponent,
];

const Directives = [InsufficientItemsDirective, OutsideClickDirective];

@NgModule({
  declarations: [...Components, ...Directives, SafeHtmlPipe],
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, RouterModule],
  exports: [
    ...Components,
    ...Directives,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SafeHtmlPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
