import { Injectable } from '@angular/core';
import { Cookie, CookieTypes, PrivacyCookie } from '../../modules/shared/models/cookie';

@Injectable({ providedIn: 'root' })
export class CookieService {
  /**
   * Checks the privacy settings and returns the cookie if allowed
   *
   * @param name
   * @param isEssential
   */
  get(name: Cookie, isEssential: boolean = false): Nullable<CookieTypes[typeof name]> {
    if (this.hasConvenienceConsent() || isEssential) {
      const value: string | null = this.getCookie(name);
      if (value) {
        return JSON.parse(value) as CookieTypes[typeof name];
      }
    }
    return null;
  }

  /**
   * Checks the privacy settings and sets the cookie if allowed
   *
   * @param name
   * @param value
   * @param days
   * @param isEssential
   */
  set(name: Cookie, value: CookieTypes[typeof name], days: number, isEssential: boolean = false): boolean {
    if (this.hasConvenienceConsent() || isEssential) {
      this.setCookie(name, JSON.stringify(value), days);
      return true;
    }
    return false;
  }

  /**
   * Deletes a cookie
   *
   * @param name
   */
  delete(name: Cookie): void {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  /**
   * Checks if the convenience consent has been given by the user to save/read data
   */
  hasConvenienceConsent(): boolean {
    return !!this.getPrivacyCookie()?.consent.convenience;
  }

  /**
   * Returns the cookie
   *
   * @param name
   * @private
   */
  private getCookie(name: Cookie): Nullable<string> {
    const cookies = document.cookie.split(';');
    const cookieName = name + '=';

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();

      if (cookie.startsWith(cookieName)) {
        return decodeURIComponent(cookie.substring(cookieName.length));
      }
    }

    return null;
  }

  /**
   * Sets the cookie
   *
   * @param name
   * @param value
   * @param days
   * @private
   */
  private setCookie(name: string, value: string, days: number): void {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);

    const cookieValue = encodeURIComponent(value) + '; expires=' + expirationDate.toUTCString() + '; path=/';
    document.cookie = name + '=' + cookieValue;
  }

  private getPrivacyCookie(): Nullable<PrivacyCookie> {
    const privacy = this.getCookie(Cookie.BOSCH_PRIVACY);
    return privacy ? (JSON.parse(privacy) as PrivacyCookie) : null;
  }
}
