import { Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'to-rc-spinner',
  templateUrl: './rc-spinner.component.html',
  styleUrls: ['./rc-spinner.component.scss'],
})
export class RcSpinnerComponent {
  @HostBinding('style.--size')
  @Input()
  size: string = '5rem';
}
