import { Component, Signal, computed, inject } from '@angular/core';

import { BANNER_SETTINGS, BannerSetting } from 'src/app/modules/shared/models/banner.config';
import { BannerService } from 'src/app/services/banner/banner.service';

@Component({
  selector: 'to-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  settingsSig: Signal<BannerSetting> = computed(() => {
    const config = this.bannerService.bannerConfigSig();

    return config !== null ? BANNER_SETTINGS[config.type] : BANNER_SETTINGS.neutral;
  });
  showCloseSig: Signal<boolean> = computed(() => this.bannerService.bannerConfigSig()?.showClose ?? true);
  textKeySig: Signal<string> = computed(() => this.bannerService.bannerConfigSig()?.textKey ?? '');
  paramsSig: Signal<{ [key: string]: string }> = computed(() => this.bannerService.bannerConfigSig()?.params ?? {});
  linkSig: Signal<{ url: string; target: string } | undefined> = computed(
    () => this.bannerService.bannerConfigSig()?.link ?? undefined
  );

  readonly bannerService = inject(BannerService);

  bannerClick(): void {
    const link = this.linkSig();
    if (link && link.url) {
      window.open(link.url, link.target);
      this.closeButtonClick();
    }
  }

  closeButtonClick(): void {
    this.bannerService.closeBannerClick();
  }
}
