import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownItem } from '../../models/dropdown-item';

@Component({
  selector: 'to-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input()
  id: string = 'dropdown-component-select';

  @Input()
  label: string;

  @Input()
  disabled: boolean = false;

  @Input()
  items: DropdownItem[] = [];

  @Input()
  selectedValue?: string;

  @Output()
  onSelect: EventEmitter<string> = new EventEmitter<string>();
}
