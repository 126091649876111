<div class="a-text-field flex-auto" outsideClick (clickOutside)="closePicker()">
  @if (label) {
    <label for="{{id}}">{{ label }}</label>
  }
  <input type="text" readonly
         placeholder="{{ format12Hours ? '--:-- --' : '--:--'}}"
         [id]="id"
         [ngModel]="value"
         (focus)="openPicker()"
         (blur)="onBlur()" />
  <button type="button" class="a-text-field__icon-close !right-0" (click)="togglePicker()">
    <frok-icon bosch="clock" />
  </button>
  @if (showPicker) {
    <div class="a-tooltip -floating-shadow-s absolute left-0" role="tooltip"
         [class.top-12]="!alignTop"
         [class.bottom-12]="alignTop">
      <div class="flex gap-1 picker-container">
        <div #columnHours class="flex flex-1 flex-col picker-column">
          @for (hour of hours; track $index) {
            <div class="-size-m cursor-pointer flex-1 flex justify-center picker-item"
                 [id]="'hour-' + hour"
                 [class.selected]="hour == selectedHour"
                 (click)="setHour(hour)">
              {{ hour }}
            </div>
          }
        </div>
        <div #columnMinutes class="flex flex-1 flex-col picker-column">
          @for (minute of minutes; track $index) {
            <div class="-size-m cursor-pointer flex-1 flex justify-center picker-item"
                 [id]="'minute-' + minute"
                 [class.selected]="minute === selectedMinute"
                 (click)="setMinute(minute)">
              {{ minute }}
            </div>
          }
        </div>
        @if (format12Hours) {
          <div class="flex flex-1 flex-col picker-column">
            @for (meridiem of meridiems; track $index) {
              <div class="-size-m cursor-pointer flex justify-center picker-item"
                   [class.selected]="meridiem === selectedMeridiem"
                   (click)="setMeridiem(meridiem)">
                {{ meridiem }}
              </div>
            }
          </div>
        }
      </div>
    </div>
  }
</div>
