import { Component, EventEmitter, Output, signal } from '@angular/core';
import { DateTimeRange, DayInMonth } from '../../models/date-time-picker';

@Component({
  selector: 'to-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrl: './date-time-picker.component.scss',
})
export class DateTimePickerComponent {
  @Output()
  onSelect = new EventEmitter<DateTimeRange>();

  @Output()
  onReset = new EventEmitter<void>();

  months = Array.from({ length: 12 }, (_, i) => {
    return new Date(0, i).toLocaleString('default', { month: 'long' });
  });
  years;
  weekdays = Array.from({ length: 7 }, (_, i) => {
    return new Date(0, 0, i + 1).toLocaleString('default', { weekday: 'short' }).slice(0, 2);
  });

  hasErrorSig = signal(false);

  selectedMonth;
  selectedYear;

  selectionStart?: Date;
  selectionEnd?: Date;
  timeStart?: string;
  timeEnd?: string;

  daysInMonth: Array<DayInMonth> = [];

  private earliestSelectableDate: Date;

  constructor() {
    const now = new Date();
    this.earliestSelectableDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();
    this.years = [currentYear];
    if (currentMonth === 0) {
      this.years.push(currentYear - 1);
    }

    this.selectedYear = currentYear;
    this.selectedMonth = this.months[currentMonth];

    this.selectMonth();
  }

  selectMonth(): void {
    const month = this.months.indexOf(this.selectedMonth);

    const start = new Date(this.selectedYear, month, 1);
    const end = new Date(this.selectedYear, month + 1, 0);
    const days: DayInMonth[] = [];

    // Add the last few days of the previous month
    for (let i = start.getDay(); i > 1; i--) {
      const day = new Date(this.selectedYear, month, -i + 2);
      days.push({
        day: day.getDate(),
        month: day.getMonth(),
        year: day.getFullYear(),
        isCurrentMonth: false,
        disabled: !this.isWithinLast30Days(day),
        selected: this.selectionStart && this.selectionEnd && day >= this.selectionStart && day <= this.selectionEnd,
      });
    }

    // Add all days of the current month
    for (let i = 1; i <= end.getDate(); i++) {
      const day = new Date(this.selectedYear, month, i);
      days.push({
        day: day.getDate(),
        month: day.getMonth(),
        year: day.getFullYear(),
        isCurrentMonth: true,
        disabled: !this.isWithinLast30Days(new Date(this.selectedYear, month, i)),
        selected: this.selectionStart && this.selectionEnd && day >= this.selectionStart && day <= this.selectionEnd,
      });
    }

    // Add the next few days of the next month
    for (let i = 1; i <= 7 - end.getDay(); i++) {
      const day = new Date(this.selectedYear, month + 1, i);
      days.push({
        day: day.getDate(),
        month: day.getMonth(),
        year: day.getFullYear(),
        isCurrentMonth: false,
        disabled: !this.isWithinLast30Days(new Date(this.selectedYear, month + 1, i)),
        selected: this.selectionStart && this.selectionEnd && day >= this.selectionStart && day <= this.selectionEnd,
      });
    }

    this.daysInMonth = days;
  }

  selectDay(day: DayInMonth): void {
    if (this.selectionStart && this.selectionEnd) {
      this.selectionStart = undefined;
      this.selectionEnd = undefined;
    }
    if (this.selectionStart) {
      this.selectionEnd = new Date(day.year, day.month, day.day);
    } else {
      this.selectionStart = new Date(day.year, day.month, day.day);
    }

    if (this.selectionEnd && this.selectionEnd < this.selectionStart) {
      const temp = this.selectionStart;
      this.selectionStart = this.selectionEnd;
      this.selectionEnd = temp;
    }

    for (let day of this.daysInMonth) {
      day.selected = this.isSelected(day);
    }

    this.checkTimeValidity();
  }

  isSelected(day: DayInMonth): boolean {
    if (this.selectionStart && this.selectionEnd) {
      return (
        this.selectionStart.getTime() <= new Date(day.year, day.month, day.day).getTime() &&
        this.selectionEnd.getTime() >= new Date(day.year, day.month, day.day).getTime()
      );
    } else if (this.selectionStart) {
      return this.selectionStart.getTime() == new Date(day.year, day.month, day.day).getTime();
    }
    return false;
  }

  isSelectionStart(day: DayInMonth): boolean {
    if (this.selectionStart && this.selectionEnd) {
      return this.selectionStart.getTime() == new Date(day.year, day.month, day.day).getTime();
    }
    return false;
  }

  isSelectionEnd(day: DayInMonth): boolean {
    if (this.selectionEnd) {
      return this.selectionEnd.getTime() == new Date(day.year, day.month, day.day).getTime();
    }
    return false;
  }

  checkTimeValidity() {
    this.hasErrorSig.set(false);
    if (this.selectionStart && this.selectionEnd && this.timeStart && this.timeEnd) {
      const start = this.assignTime(new Date(this.selectionStart), this.timeStart);
      const end = this.assignTime(new Date(this.selectionEnd), this.timeEnd);
      this.hasErrorSig.set(start > end);
    }
  }

  reset(): void {
    this.selectionStart = undefined;
    this.selectionEnd = undefined;
    this.timeStart = undefined;
    this.timeEnd = undefined;
    this.hasErrorSig.set(false);
    for (let day of this.daysInMonth) {
      day.selected = false;
    }
    this.onReset.emit();
  }

  onSubmit(): void {
    if (this.selectionStart && this.selectionEnd) {
      let startDate = new Date(this.selectionStart);
      let endDate = new Date(this.selectionEnd);
      if (this.timeStart) {
        startDate = this.assignTime(startDate, this.timeStart);
      }
      if (this.timeEnd) {
        endDate = this.assignTime(endDate, this.timeEnd);
      } else {
        endDate.setHours(23, 59, 59);
      }
      this.onSelect.emit({
        start: startDate,
        end: endDate,
      });
    }
  }

  isWithinLast30Days(date: Date): boolean {
    return date >= this.earliestSelectableDate && date <= new Date();
  }

  private assignTime(date: Date, time: string): Date {
    const [hoursStr, minutesStr] = time.split(':').map((str) => str.trim());
    let [hours, minutes] = [parseInt(hoursStr), parseInt(minutesStr)];

    time = time.toUpperCase();
    if (time.includes('AM') || time.includes('PM')) {
      const isPM = time.includes('PM');
      if (hours === 12 && !isPM) {
        hours = 0;
      } else if (isPM && hours !== 12) {
        hours += 12;
      }
    }

    date.setHours(hours);
    date.setMinutes(minutes);

    return date;
  }
}
