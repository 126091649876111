import { BoschIcon } from '../components/frok-icon/icons';

export enum BannerType {
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface BannerConfig {
  type: BannerType;
  textKey: string;
  params?: { [key: string]: string };
  link?: {
    url: string;
    target: string;
  };
  showClose: boolean;
}

export interface BannerSetting {
  icon: BoschIcon;
  class: string;
  role: string;
}

export const BANNER_SETTINGS: { [key in BannerType]: BannerSetting } = {
  [BannerType.NEUTRAL]: {
    icon: 'alert-info',
    class: '-neutral',
    role: 'status',
  },
  [BannerType.SUCCESS]: {
    icon: 'alert-success',
    class: '-success',
    role: 'status',
  },
  [BannerType.WARNING]: {
    icon: 'alert-warning',
    class: '-warning',
    role: 'alert',
  },
  [BannerType.ERROR]: {
    icon: 'alert-error',
    class: '-error',
    role: 'alert',
  },
};
